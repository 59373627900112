.default-form {
    input[type="text"],
    input[type="email"],
    input[type="number"],
    select,
    textarea {
        width: 100%;
        background-color: theme('colors.cloud');
        border: 1px solid theme('colors.green');
        border-radius: 999px;
        padding: 16px 28px;
        
        @screen l {
            padding: 24px 40px;
        }
        
        &::placeholder {
            color: theme('colors.green');
        }
    }
    
    select {
        appearance: none;
        padding-right: 70px;
        
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_317_13790' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='17'%3E%3Crect width='16' height='16' transform='matrix(-4.37114e-08 1 1 4.37114e-08 0 0.109375)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_317_13790)'%3E%3Cpath d='M8.8 13.0448L8.8 0.109375L7.2 0.109375L7.2 13.0448L1.124 6.96884L-3.49691e-07 8.10938L8 16.1094L16 8.10938L14.876 6.96884L8.8 13.0448Z' fill='%23092F00'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: calc(100% - 30px) center;
    }
}
