@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */
    
    .h1 {
        @apply text-32 leading-[1.25] l:text-60 l:leading-[1.15] font-medium;
    }
    .h2 {
        @apply text-26 leading-[1.3] l:text-46 l:leading-[1.19] font-medium;
    }
    .h3 {
        @apply text-23 leading-[1.3] l:text-40 font-medium;
    }
    .h4 {
        @apply text-20 leading-[1.35] l:text-28;
    }
    .h4-medium {
        @apply h4 font-medium;
    }
    .h5 {
        @apply text-18 leading-[1.3] l:text-22 l:leading-[1.4] font-medium;
    }
    .h6 {
        @apply text-16 leading-[1.4] l:text-20 font-medium;
    }
    
    .body-l {
        @apply text-18 leading-[1.6] l:text-22 l:leading-[1.6];
    }
    .body-m {
        @apply text-16 leading-[1.6] l:text-20 l:leading-[1.6];
    }
    .body-s {
        @apply text-14 leading-[1.6] l:text-18 l:leading-[1.6];
    }
    
    .meta {
        @apply text-14 leading-[1.3] l:text-16 l:leading-[1.4];
    }
    .category {
        @apply uppercase text-12 leading-[1.2] l:text-14 l:leading-[1.4];
    }

    .hit {
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .top-padding {
        padding-top: 88px;
        
        @screen l {
            padding-top: 120px;
        }
    }
    
    .grapper {
        @apply wrapper grid gutters-x;
    }

    .variable-ratio {
        padding-bottom: calc((1/var(--ratio-s))*100%);
        
        @screen m {
            padding-bottom: calc((1/var(--ratio-m, --ratio-s))*100%);
        }
        @screen l {
            padding-bottom: calc((1/var(--ratio-l, --ratio-m))*100%);
        }
    }
    
    .bullet-list {
        li {
            position: relative;
            padding-left: 24px;
            
            @screen l {
                padding-left: 32px;
            }
            
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0.2em;
                width: 16px;
                height: 16px;
                border-radius: 10px;
                background-color: #D9D9D9;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath fill='%231C1B1F' d='M3.96 7.77.27 4.2l.74-.71 2.95 2.84 6.33-6.1.74.7-7.07 6.83Z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 60% 60%;
                
                @screen l {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        
        &--highlighted {
            li:before {
                background-color: theme('colors.tomato');
            }
        }
    }
 
    
    .radio,
    .checkbox {
        cursor: pointer;
        input {
            appearance: none;
            width: 0;
            height: 0;
            display: block;
        }

        input:focus {
            outline: 0;

            + .radio__inner,
            + .checkbox__inner {
                outline: 1px solid theme('colors.tomato');
                outline-offset: 1px;
            }
        }

        &__inner {
            user-select: none;
        }
    }

    .checkbox {
        &__bullet:before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            left: 2px;
            top: 2px;
            @apply bg-tomato;
            opacity: 0;
        }

        input[type="checkbox"]:checked + .checkbox__inner {
            //@apply text-foreground;

            .checkbox__bullet:before {
                opacity: 1;
            }
        }
    }

    .radio {
        &__bullet:before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            left: 0;
            top: 0;
            @apply bg-tomato;
            opacity: 0;
        }

        input[type="radio"]:checked + .radio__inner {
            //@apply text-foreground;

            .radio__bullet:before {
                opacity: 1;
            }
        }
    }    
}
