.page-header {
    &__bar {
        transition-property: transform;
        transition-duration: 400ms;
        transition-delay: 0ms;
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);

        .is-transparent:not(.is-locked) & {

        }

        .is-hidden:not(.is-locked) & {
            transform: translate(0, -101%);
            transition-duration: 250ms;
        }

        .is-locked & {
            transition-delay: 0ms;
        }

        .is-forced & {
            transition-duration: 0ms !important;
            transition-delay: 0ms !important;
        }
    }
}
