.wysiwyg {
    p, ul, ol {
        margin-bottom: 1.6em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        @apply h3 mb-12;
    }

    h3 {
        @apply h4 font-bold mb-12;
    }
    
    b, strong {
        font-weight: 700;
    }
    i, em {
        font-style: italic;
    }

    li {
        display: list-item;
        position: relative;
        padding: 0 0 0 24px;

        @screen l {
            padding: 0 0 0 32px;
        }

    }

    ol {
        counter-reset: item;
    }
    
    ul > li:before {
        content: '•';
        font-size: 1.3em;
        position: absolute;
        left: 9px;
        top: -0.18em;
        display: block;

        @screen l {
            left: 11px;
        }
    }
    
    ol > li:before {
            content: counter(item) ".";
            counter-increment: item;
            
            position: absolute;
            left: 2px;
            top: 0;
            display: block;

            @screen l {

            }
        }        

    a {
        text-decoration: underline;
    }

    &--recipe {
        
        h3 {
            @apply h5;
            
            margin-top: 40px;
            
            @screen l {
                margin-top: 56px;
            }
            
            &:first-child {
                margin-top: 0;
            }
        }
        
        li {
            padding: 0 0 0 24px;

            @screen l {
                padding: 0 0 0 36px;
            }

        }

        ul > li:before {
            content: '';
            position: absolute;
            left: 1px;
            top: 0.25em;
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 10px;
            background-color: theme('colors.cloud');
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath fill='%231C1B1F' d='M3.96 7.77.27 4.2l.74-.71 2.95 2.84 6.33-6.1.74.7-7.07 6.83Z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60% 60%;

            @screen l {
                width: 20px;
                height: 20px;
            }
        }
        
        ol > li {
            margin-bottom: 12px;
            
            &:last-child {
                margin-bottom: 0;
            }
            
            @screen l {
                margin-bottom: 16px;
            }
        }
        
        ol > li:before {
            @apply meta;
            
            content: counter(item);
            counter-increment: item;
            
            position: absolute;
            left: 1px;
            top: 0.25em;
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 10px;
            background-color: theme('colors.cloud');
            text-align: center;
            padding-top: 1px;

            @screen l {
                width: 24px;
                height: 24px;
                padding-top: 2px;
            }
        }        
    }
}
