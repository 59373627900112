:root {
    // Hotfix for Tailwind 3's transform utilities being buggy in Safari <=14
    // https://github.com/tailwindlabs/tailwindcss/discussions/8788
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-out-cubic;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.outline,
html.no-outline * {
    outline: none !important;
}

fieldset {
    min-inline-size: 100%;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@view-transition {
    navigation: auto;
}
/*
@keyframes move-out {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes move-in {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

::view-transition-old(root) {
  animation: 0.4s ease-in both move-out;
}

::view-transition-new(root) {
  animation: 0.4s ease-in both move-in;
}
*/
